<template>
  <div class="app-container sub-device-componennt">
    <div class="tree-container">
      <div class="tree-title">分组树</div>
      <div class="tree-content" v-if="treeList.length">
        <el-tree
          :data="treeList"
          @node-click="handleNodeClick"
          node-key="id"
          :default-expanded-keys="[parrentId, currentId]"
          :highlight-current="true"
          :expand-on-click-node="false"
          draggable
          :allow-drop="allowDrop"
          @node-drag-end="handleDragEnd"
          ref="tree"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @mouseenter="mouseoverNode(node)"
            @mouseleave="mouseoutNode(node)"
          >
            <span>{{ node.label }}</span>
            <template>
              <span class="line-icons">
                <img
                  src="../../../../assets/images/tree_add_icon.png"
                  @click="() => appendNode(node.data)"
                  alt=""
                />
                <img
                  src="../../../../assets/images/tree_edit_icon.png"
                  @click="() => editNode(node.data)"
                  alt=""
                />
                <img
                  src="../../../../assets/images/tree_delete_icon.png"
                  @click="() => removeNode(node, data)"
                  alt=""
                />
                <!-- <el-button
                  :style="{ marginLeft: '20px' }"
                  type="text"
                  @click="() => appendNode(node.data)"
                >
                  <i class="el-icon-circle-plus-outline"></i>
                </el-button>

                <el-button
                  :style="{ marginLeft: '10px' }"
                  type="text"
                  @click="() => editNode(node.data)"
                >
                  <i class="el-icon-edit-outline"></i>
                </el-button>

                <el-button type="text" @click="() => removeNode(node, data)">
                  <i class="el-icon-delete delete-btn"></i>
                </el-button> -->
              </span>
            </template>
          </span>
        </el-tree>
      </div>
      <div v-else class="init-button">
        <el-button type="primary" @click="appendNode('', 'init')" size="small"
          >添加分组</el-button
        >
      </div>
    </div>
    <div class="right-part">
      <div class="active-node-label">
        {{ activeNodeLabel }}
      </div>
      <div class="filter-line">
        <span>
          <el-button type="primary" @click="bindDevice">绑定设备</el-button>
          <el-tooltip
            class="item"
            effect="dark"
            :disabled="device.multipleSelection.length > 0"
            :content="$t('commons.multipleSelectionOne')"
            placement="right"
            ><el-button
              @click="batchDelete"
              type="danger"
              :class="
                device.multipleSelection.length === 0 && 'not-allowed-button'
              "
              icon="el-icon-delete"
              plain
              >{{ $t("commons.delete") }}
            </el-button>
          </el-tooltip>
          <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
            >刷新</el-button
          >
        </span>
      </div>
      <div class="grid-content">
        <div class="table-container">
          <finalTable
            ref="finalTableRef"
            :datas="dataset"
            @tableEventHandler="tableEventHandler"
          />
        </div>
      </div>
    </div>

    <!-- 创建父类分组对话框 -->
    <el-dialog
      :visible.sync="device.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ device.title }}
      </template>
      <el-form
        label-position="top"
        :model="device.form"
        :rules="rules"
        ref="deviceForm"
      >
        <el-row :gutter="40">
          <el-col :span="12" v-if="device.state === 'add'">
            <el-form-item
              :label="$t('vlink.device.groupLevel')"
              prop="groupLevel"
            >
              <el-radio-group v-model="device.form.groupLevel" size="small">
                <el-radio label="1" border>同级</el-radio>
                <el-radio label="2" border>子级</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.groupName')"
              prop="groupName"
            >
              <el-input
                v-model="device.form.groupName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleDeviceSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 绑定设备 -->
    <el-dialog
      :visible.sync="bind.bindDialogVisible"
      :before-close="cancelBindDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ bind.title }}
      </template>
      <el-form
        label-position="top"
        :model="bind.form"
        :rules="bindRules"
        ref="bindForm"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <!-- <el-form-item
              :label="$t('vlink.device.deviceDescription')"
              prop="productOrDevice"
            >
              <el-cascader
                class="cascader-content"
                clearable
                placeholder="产品/设备"
                popper-class="product-device-poper"
                v-model="bind.form.productOrDevice"
                :props="props"
                collapse-tags
              ></el-cascader>
            </el-form-item> -->
            <el-form-item prop="product" :label="$t('vlink.rule.product')">
              <el-select
                v-model="bind.form.product"
                placeholder="产品"
                collapse-tags
                multiple
                filterable
                style="width: 100%"
                @change="productChange"
              >
                <el-option
                  v-for="item in productOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="parentDeviceId" label="网关">
              <el-select
                v-model="bind.form.parentDeviceId"
                placeholder="网关"
                filterable
                clearable
                style="width: 100%"
                @change="gatewayChange"
              >
                <el-option
                  v-for="item in gatewayOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="device"
              :label="$t('vlink.rule.device')"
              :rules="[
                {
                  required:
                    bind.form.product.length <= 1 && !bind.form.parentDeviceId,
                  message: '请选择设备',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="bind.form.device"
                :disabled="bind.form.product.length > 1"
                placeholder="设备"
                style="width: 100%"
                filterable
                reserve-keyword
                collapse-tags
                multiple
                remote
                clearable
                :remote-method="deviceRemoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in deviceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.groupName')"
              prop="bindGroupId"
            >
              <div class="block">
                <Treeselect
                  placeholder="请选择分组"
                  v-model="bindGroupId"
                  :multiple="false"
                  :default-expand-level="Infinity"
                  :options="bindDeviceTreeList"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelBindDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="bindDeviceGroup">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createDeviceGroup,
  updateDeviceGroup,
  findDeviceGroup,
  findDeviceList,
  findDeviceGroupList,
  bindDeviceGroup,
  deleteBindDevice,
  batchBindDevice,
  deleteGroup,
  groupOrderOn,
  findNotExistsGroupDeviceList,
} from "@/api/ruge/vlink/device/device";
import finalTable from "@/components/FinalTable";
import { getProductList } from "@/api/ruge/vlink/product/product";
import { listToTree } from "@/utils/tree";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    finalTable,
    Treeselect,
  },
  data() {
    const validateBindGroupId = (rule, value, callback) => {
      console.log("this.bindGroupId", this.bindGroupId);
      if (!this.bindGroupId) {
        callback(new Error("请选择分组"));
      } else {
        callback();
      }
    };
    return {
      gatewayOptions: [],
      productOptions: [],
      deviceOptions: {},
      loading: false,
      deviceQueryParams: {
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
        parentDeviceId: null,
      },
      activeNodeLabel: "",
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "productName",
            label: this.$t("vlink.product.productName"),
            width: "200",
          },
          {
            prop: "deviceName",
            label: this.$t("vlink.device.deviceName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.device.deviceDescription"),
            width: "",
          },
          {
            prop: "parentDeviceName",
            label: this.$t("vlink.device.parentDeviceName"),
            width: "",
          },
          {
            prop: "deviceStatus",
            label: this.$t("vlink.device.deviceStatus"),
            width: "120",
          },
          // {
          //   prop: "creationDate",
          //   label: this.$t("vlink.commons.registTime"),
          //   width: "",
          // },
          // {
          //   prop: "activationTime",
          //   label: this.$t("vlink.device.activationTime"),
          //   width: "",
          // },
          // {
          //   prop: "lastOnlineTime",
          //   label: this.$t("vlink.device.lastOnlineTime"),
          //   width: "",
          // },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        // 表格内容配置
        detailConfig: {
          enableStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          deviceStatus: {
            type: "tags",
            INACTIVE: {
              type: "info",
              label: "未激活",
            },
            ONLINE: {
              type: "success",
              label: "在线",
            },
            OFFLINE: {
              type: "danger",
              label: "离线",
            },
            OFFLINELONG: {
              type: "danger",
              label: "离线超7天",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        searchLineConfig: {
          deviceName: {
            type: "input",
            label: "设备标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备标识码",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "设备名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备名称",
            prefixIcon: "el-icon-search",
          },
          productName: {
            type: "input",
            label: "所属产品",
            value: "",
            placeholder: "请选择所属产品",
            actionType: "goSearch",
            prefixIcon: "el-icon-search",
          },
          nodeType: {
            type: "selector",
            label: "节点类型",
            value: "",
            placeholder: "请选择节点类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "直连设备",
                value: "DIRECTDEVICE",
              },
              {
                label: "网关设备",
                value: "GATEWAY",
              },
              {
                label: "网关子设备",
                value: "SUBDEVICE",
              },
            ],
          },
          parentDeviceName: {
            type: "selector",
            placeholder: "请选择节点类型",
            value: "",
            label: "所属网关",
            actionType: "goSearch",
            optionList: [],
          },
          deviceStatus: {
            type: "selector",
            label: "设备状态",
            value: "",
            placeholder: "请选择设备状态",
            actionType: "goSearch",
            optionList: [
              {
                label: "未激活",
                value: "INACTIVE",
              },
              {
                label: "在线",
                value: "ONLINE",
              },
              {
                label: "离线",
                value: "OFFLINE",
              },
            ],
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      groupList: [],
      treeList: [],
      bindDeviceTreeList: [],
      cascaderList: [],
      device: {
        multipleSelection: [],
        addDialogVisible: false,
        title: null,
        state: "add",
        form: {
          groupId: null,
          groupParentId: null,
          groupName: null,
          description: null,
          groupLevel: "1",
        },
      },
      loadingFlag: false,
      bind: {
        bindDialogVisible: false,
        title: null,
        form: {
          // productOrDevice: null,
          product: [],
          device: [],
          parentDeviceId: null,
        },
      },
      bindGroupId: null,
      listQuery: {
        groupId: null,
        current: 1,
        rowCount: 10,
        deviceName: null,
        deviceStatus: null,
        nodeType: null,
        productKey: null,
        registerStartTime: null,
        registerEndTime: null,
        activeStartTime: null,
        activeEndTime: null,
        lastStartTime: null,
        lastEndTime: null,
      },
      parrentId: "",
      currentId: "",
      selectNode: {},
      rules: {
        groupName: [
          { required: true, message: "请填写分组名称", trigger: "blur" },
        ],
      },
      bindRules: {
        // productOrDevice: [
        //   { required: true, message: "请选择设备", trigger: "blur" },
        // ],
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"],
          },
        ],
        bindGroupId: [
          { required: true, trigger: "blur", validator: validateBindGroupId },
        ],
      },
      params1: [],
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.findDeviceGroup();
    this.getProductListHandler();
    this.getGatewayList();
  },
  methods: {
    getGatewayList() {
      findDeviceList({ nodeType: "GATEWAY" }).then((deviceList) => {
        this.gatewayOptions = deviceList.map((item) => ({
          label: item.deviceName,
          value: item.deviceId,
        }));
        this.dataset.searchLineConfig.parentDeviceName.optionList = deviceList
          .filter((item) => item.nodeType === "GATEWAY")
          .map((item) => ({
            label: item.deviceName,
            value: item.deviceId,
          }));
      });
    },
    gatewayChange() {
      if (this.bind.form.product && this.bind.form.product.length) {
        this.deviceRemoteMethod();
      }
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findDeviceGroupList();
    },
    getProductListHandler() {
      getProductList().then((productList) => {
        const nodes = productList.map((item) => ({
          value: item.productKey,
          label: item.productName,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.productOptions = nodes;
      });
    },
    productChange() {
      // 清空一选择的设备、属性
      this.bind.form.device = "";
      this.deviceOptions = [];
      // 根据产品获取设备
      this.deviceRemoteMethod();
    },
    deviceRemoteMethod(query) {
      console.log("this.form.product", this.bind.form.product);
      console.log("this.bindGroupId", this.bindGroupId);
      if (this.bind.form.product.length > 1) return;
      if (this.bind.form.product) {
        this.loading = true;
        let params = this.deviceQueryParams;
        params.description = query;
        params.productKey = this.bind.form.product[0] || "";
        params.groupId = this.bindGroupId;
        params.parentDeviceId = this.bind.form.parentDeviceId;
        console.log("params", params);
        findNotExistsGroupDeviceList(params)
          .then((res) => {
            const currentDevices = res.map((item) => {
              return {
                value: `${item.deviceName}_${item.deviceId}`,
                label: item.description
                  ? `${item.description} (${item.deviceName})`
                  : `${item.deviceName} (${item.deviceName})`,
              };
            });
            this.deviceOptions = currentDevices;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deviceOptions = [];
      }
    },
    allowDrop(node) {
      if (node.data.parentId == -1) {
        return false;
      }
      return true;
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      let tempParams = JSON.parse(JSON.stringify(this.treeList));
      this.currentId = draggingNode.data.id;
      this.params1 = [];
      if (dropType === "inner") {
        this.changeGroupParentId(
          tempParams,
          draggingNode.data.id,
          dropNode.data.groupId
        );
      } else if (dropType === "before") {
        this.changeGroupParentId(
          tempParams,
          draggingNode.data.id,
          dropNode.parent.data.groupId
        );
      }
      // if (dropNode.)
      this.buildTreeSortNo(tempParams);
      this.buildTreeDatas(tempParams);
      groupOrderOn(this.params1)
        .then(() => {
          this.$message.success("保存成功！");
        })
        .finally(() => {
          this.findDeviceGroup();
          this.params1 = [];
        });
    },
    changeGroupParentId(list, draggingNodeId, groupId) {
      list.forEach((item) => {
        if (item.id === draggingNodeId) {
          item.groupParentId = groupId;
        } else {
          if (item.children && item.children.length) {
            this.changeGroupParentId(item.children, draggingNodeId, groupId);
          }
        }
      });
    },
    buildTreeDatas(list) {
      console.log("aaaaaaaa");
      list.forEach((item) => {
        let tempItem = JSON.parse(JSON.stringify(item));
        delete tempItem.children;
        this.params1.push(tempItem);
        if (item.children && item.children.length) {
          if (item.children && item.children.length) {
            console.log("bbbbbbbb");
            this.buildTreeDatas(item.children);
          }
        }
      });
    },
    buildTreeSortNo(treeList) {
      treeList.forEach((item, index) => {
        item.orderNo = index;
        if (item.children && item.children.length) {
          this.buildTreeSortNo(item.children);
        }
      });
    },
    bindHandleNodeChange(data, checked) {
      if (checked) {
        this.$refs.bindTree.setCheckedNodes([data]);
        this.selectNode = data;
        console.log("this.selectNode", this.selectNode);
      }
    },
    bindHandleNodeClick(data) {
      console.log("xx1", data);
    },
    // 点击树 ，获取绑定设备列表
    handleNodeClick(data) {
      console.log("data", data);
      this.activeNodeLabel = data.label;
      this.listQuery.groupId = data.id;
      this.parentId = data.groupParentId;
      this.currentId = data.id;
      this.findDevicePage();
    },
    // 获取设备列表
    findDeviceGroupList() {
      this.loadingFlag = true;
      let param = this.listQuery;
      sessionStorage.setItem(
        "groupDevice_list_query_params",
        JSON.stringify(param)
      );
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      findDeviceGroupList(param)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch((error) => {
          console.log(`查询失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    // 取消保存
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
      this.clear();
    },
    // 清空树内容
    clear() {
      this.device.form.groupParentId = null;
      this.device.form.groupName = null;
      this.device.form.description = null;
      this.device.form.groupId = null;
    },
    // 取消绑定保存
    cancelBindDeviceSave() {
      this.bind.bindDialogVisible = false;
      this.bindGroupId = null;
      this.bind.form.product = [];
      this.bind.form.device = [];
      this.bind.form.parentDeviceId = null;
    },
    // 创建父类分组对话框
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.device.form.groupId != null) {
            updateDeviceGroup(this.device.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.findDeviceGroup();
                this.cancelDeviceSave();
                this.clear();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            if (this.device.form.groupLevel === "1") {
              this.device.form.groupParentId = this.parentId;
            }
            createDeviceGroup(this.device.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.findDeviceGroup();
                this.cancelDeviceSave();
                this.clear();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    // 绑定设备
    bindDeviceGroup() {
      this.$refs.bindForm.validate((v) => {
        if (v) {
          let result = [];
          // 有选设备，那就只有一个产品
          if (this.bind.form.device.length) {
            this.bind.form.device.forEach((item) => {
              result.push({
                productKey: this.bind.form.product[0],
                deviceName: item.split("_")[0],
                groupId: this.bindGroupId,
                deviceId: item.split("_")[1],
                parentDeviceId: this.bind.form.parentDeviceId,
              });
            });
          } else {
            // 没有选设备，有多个产品
            this.bind.form.product.forEach((item) => {
              result.push({
                productKey: item,
                groupId: this.bindGroupId,
                parentDeviceId: this.bind.form.parentDeviceId,
              });
            });
          }
          bindDeviceGroup(result)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              this.findDeviceGroupList();
              this.cancelBindDeviceSave();
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            });
        }
      });
    },
    bindDevice() {
      this.bind.title = this.$t("vlink.device.bindDevice");
      this.bind.bindDialogVisible = true;
      this.bind.form.product = [];
      this.bind.form.device = [];
      if (this.currentId) this.bindGroupId = this.currentId;
    },
    //初始设备分组列表
    findDeviceGroup() {
      findDeviceGroup().then((res) => {
        res.sort(function (a, b) {
          return a.orderNo - b.orderNo;
        });
        this.groupList = res.filter((item) => item.groupParentId == null);
        let data = listToTree(res, "groupId", "groupParentId");
        this.treeList = this.recursivePosition(data);
        this.bindDeviceTreeList = JSON.parse(JSON.stringify(this.treeList));
        this.cascaderList = this.cascader(data);
      });
    },
    listSortByOrderNo(list) {
      if (!list) return;
      // console.log(11111);
      list = list.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });
      list.forEach((item) => {
        if (item.children && item.children.length) {
          this.listSortByOrderNo(list.children);
        }
      });
    },
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.groupName;
        v.id = v.groupId;
        v.show = false;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      return data;
    },
    cascader(row) {
      let data = row.map((v) => {
        v.label = v.groupName;
        v.value = v.groupId;
        if (v.children != undefined) {
          this.cascader(v.children);
        }
        return v;
      });
      return data;
    },
    tableEventHandler(datas) {
      if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewDeviceDetail(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.groupId, datas.row.deviceId);
            break;
        }
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.findDeviceGroupList();
      } else if (datas.type === "goSearch") {
        this.dealTime(datas);
        this.listQuery.parentDeviceId =
          datas.params.parentDeviceName === undefined
            ? this.listQuery.parentDeviceName
            : datas.params.parentDeviceName;
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.findDevicePage();
      }
    },
    findDevicePage() {
      this.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.findDeviceGroupList();
    },
    deleteDevice(groupId, deviceId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteBindDevice({ groupId, deviceId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDeviceGroupList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 批量删除
    batchDelete() {
      if (this.device.multipleSelection.length < 1) return;
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchBindDevice({
            deviceIdList: this.device.multipleSelection,
            groupId: this.listQuery.groupId,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDeviceGroupList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // allowDrop() {
    //   return true;
    // },
    mouseoverNode(node) {
      node.data.show = true;
    },
    mouseoutNode(node) {
      node.data.show = false;
    },
    appendNode(node, type) {
      this.device.state = type === "init" ? "init" : "add";
      this.device.form.groupParentId = node.id;
      this.device.title = this.$t("vlink.device.addGroup");
      this.device.addDialogVisible = true;
    },
    editNode(node) {
      this.device.state = "edit";
      this.device.form.groupParentId = node.groupParentId;
      this.device.form.groupId = node.groupId;
      this.device.form.groupName = node.groupName;
      this.device.form.description = node.description;
      this.device.title = this.$t("vlink.device.editSubGroup");
      this.device.addDialogVisible = true;
    },
    removeNode(node, data) {
      if (node.isLeaf) {
        this.$confirm(
          "删除将会解绑分组下所有设备，确定删除吗？",
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteGroup({ groupId: data.id })
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.deleteSuccess"),
                });
                // const parent = node.parent;
                // const children = parent.data.children || parent.data;
                // const index = children.findIndex(d => d.id === data.id);
                // children.splice(index, 1);
                this.findDeviceGroup();
                this.findDevicePage();
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      } else {
        // 失败提示
        this.$message({
          type: "info",
          message: "有子节点，不可删除",
        });
      }
    },
    dealTime(datas) {
      // 注册时间
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.listQuery.registerStartTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.listQuery.registerEndTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        this.listQuery.registerStartTime = "";
        this.listQuery.registerEndTime = "";
      }
      // 激活时间
      if (
        datas.params.activationTime &&
        datas.params.activationTime.length > 0
      ) {
        this.listQuery.activeStartTime = datas.params.activationTime[0]
          ? new Date(datas.params.activationTime[0]).getTime()
          : null;
        this.listQuery.activeEndTime = datas.params.activationTime[1]
          ? new Date(datas.params.activationTime[1]).getTime()
          : null;
        delete datas.params.activationTime;
      } else {
        this.listQuery.activeStartTime = "";
        this.listQuery.activeEndTime = "";
      }
      // 最后上线时间
      if (
        datas.params.lastOnlineTime &&
        datas.params.lastOnlineTime.length > 0
      ) {
        this.listQuery.lastStartTime = datas.params.lastOnlineTime[0]
          ? new Date(datas.params.lastOnlineTime[0]).getTime()
          : null;
        this.listQuery.lastEndTime = datas.params.lastOnlineTime[1]
          ? new Date(datas.params.lastOnlineTime[1]).getTime()
          : null;
        delete datas.params.lastOnlineTime;
      } else {
        this.listQuery.lastStartTime = "";
        this.listQuery.lastEndTime = "";
      }
      return datas;
    },
    // 查看详情
    viewDeviceDetail(row) {
      const { origin, pathname } = window.location;
      const { productKey, deviceName } = row;
      const targetUrl = `${origin}${pathname}#/iot/device/detail?productKey=${productKey}&deviceName=${deviceName}`;
      window.open(targetUrl, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.sub-device-componennt {
  background: #f1f2f4;
  padding: 0;
  display: flex;
  .tree-container {
    background: #fff;
    width: 250px;
    overflow: auto;
    border-radius: 10px;
    min-height: calc(100vh - 96px);
    .tree-title {
      padding-left: 20px;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      font-weight: bold;
      color: #152c5b;
    }
    .tree-content {
      margin-top: 10px;
      .custom-tree-node {
        display: flex;
        align-items: center;
        .line-icons {
          margin: 0 15px;
          img {
            height: 14px;
            width: 14px;
          }
          img + img {
            margin-left: 5px;
          }
        }
      }
    }
    .init-button {
      padding: 10px;
    }
  }
  .right-part {
    overflow: auto;
    box-sizing: border-box;
    padding: 0 20px;
    border-radius: 10px;
    background: #fff;
    width: calc(100% - 260px);
    margin-left: 10px;
    .active-node-label {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .cascader-content {
    width: 100%;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.tree {
  overflow-y: hidden;
  overflow-x: scroll;
  width: 80px;
  height: 500px;
}
.el-tree {
  min-width: 100%;
  display: inline-block !important;
  font-size: 14px;
  color: #152c5b;
}
v::deep .el-icon-caret-right:before {
  color: #152c5b;
}
.option {
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: #fff;
}

.tree {
  padding: 4px 20px;
  font-weight: 400;
}
</style>